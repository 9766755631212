<template>
  <div class="login flex-c">
    <div class="alert">
      <el-alert title="旧版系统即将下线，移步到新的系统，系统地址是：https://js56918.com/" type="warning" show-icon center :closable="false"
        size="large" />
    </div>
    <div class="login_box flex-r">
      <div class="login_box_lf flex-c">
        <div class="welcome fc-white">
          <span class="fz-18">欢迎来到</span>
          <p class="fz-24">{{ $store.state.company }}</p>
        </div>
      </div>
      <div class="login_box_rt">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0">
          <el-form-item label>
            <h2 class="text-align-center">登录</h2>
          </el-form-item>
          <el-form-item label prop="username">
            <el-input v-model="ruleForm.username" placeholder="请输入用户名" prefix-icon="el-icon-user" maxlength="11">
              <i slot="prefix" class="iconfont icon-phone"></i>
            </el-input>
          </el-form-item>
          <el-form-item label prop="password">
            <el-input v-model="ruleForm.password" type="password" placeholder="请输入密码" prefix-icon="el-icon-key">
              <i slot="prefix" class="iconfont icon-password"></i>
            </el-input>
          </el-form-item>
          <div class="remember flex-r">
            <el-checkbox v-model="ruleForm.rememberMe">记住密码</el-checkbox>
            <!--<a>找回密码</a>-->
          </div>
          <el-form-item>
            <el-button :loading="loading" type="primary" class="login--btn" @click="submit('ruleForm')">登录</el-button>
          </el-form-item>
          <div class="account--oper text-align-center fz-12">
            <span class="fz-cl-light">没有密码?</span>
            <a class="fz-cl-main" @click.stop="jumpPage('/register')">设置密码</a>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Login",
  components: {},
  props: {},
  data() {
    const phoneCodeVerify = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入手机号码"));
      } else if (/^[0-9]{11}$/.test(value) === false) {
        return callback(new Error("请输入正确格式的11位手机号码"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        username: "",
        password: "",
        rememberMe: true
      },
      rules: {
        username: [
          { required: true, validator: phoneCodeVerify, trigger: "blur" },
          { required: true, message: "请输入用户名", trigger: "blur" }
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      },
      loading: false,
      redirect: undefined
    };
  },
  //计算属性
  computed: {},
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created() {
    var that = this;
    document.onkeydown = () => {
      var key = window.event.keyCode;
      if (key == 13) {
        that.submit("ruleForm");
      }
    }
  },

  //keep-alive 组件激活时调用
  activated() { },
  //keep-alive 组件停用时调用。
  deactivated() { },
  //方法
  methods: {
    submit(formName) {
      console.log(this.ruleForm)
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true;
          this.$store.dispatch("Login", this.ruleForm).then(res => {
            this.loading = false;
            this.$message.success("登录成功")
            console.log(res)
            if (res.code === 1 && res.id_token) {
              localStorage.setItem(
                "token", "Bearer " + res.id_token
              );
              this.$store.dispatch("GetInfo");
              setTimeout(() => {
                this.$router.push({ path: "/index" });
              }, 1000);
            }
          }).catch(() => {
            //this.$router.push({ path: this.redirect || "/login" });
            this.loading = false;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      })
    },
    jumpPage(url) {
      this.$router.push({ path: url, query: {} });
    }
  },
  mounted() {
    this.ruleForm.username = this.$route.query.newusername
    this.ruleForm.password = this.$route.query.newpsd
    console.log(this.ruleForm.username)
    console.log(this.ruleForm.password)
  },
}
</script>
<style scoped lang="scss">
.alert {
  position: fixed;
  top: 100px;
  font-size: 16px !important;

  :deep(.el-alert__title) {
    font-size: 16px !important;
    line-height: 20px !important;
  }
}
</style>